import { useModal } from 'vue-final-modal';
import { defineAsyncComponent, ref } from '#imports';

const isPopuplVisible = ref(false);
export const useProductReview = () => {
  const openProductReviewPopup = (id = '') => {
    if (isPopuplVisible.value) return;

    const { open, close } = useModal({
      modalId: 'productReview',
      component: defineAsyncComponent(
        () => import('@/components/popups/ProductReview.vue'),
      ),
      attrs: {
        targetProductId: id,
        closeModal: () => {
          close();
        },
        onClosed() {
          isPopuplVisible.value = false;
        },
      },
    });

    isPopuplVisible.value = true;

    open();
  };

  return {
    openProductReviewPopup,
  };
};
